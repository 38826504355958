import React, { useEffect, useState, useContext, useCallback } from 'react';
import Input from '../ui/Input';
import Select from '../ui/Select';
import Btn from '../ui/Btn';
import Popup from '../ui/Popup';
import Cookies from 'js-cookie';
import Tooltip from '../ui/Tooltip';

import { optionsPayment, optionsCurrencySolana, optionsCurrencyEth, optionsCurrencyBnb, usdtRate } from '../../config';
import { LanguageContext } from '../../context/languageContext';
import { translations } from '../../translations';
import useEthConnect from '../../connectWallets/useEthConnect';
// import useBnbConnect from '../../connectWallets/useBnbConnect';
import { useSolanaConnect } from '../../connectWallets/useSolanaConnect';
import { GetBinanceTicker } from '../../services/GetBinanceTicker';
import { debounce } from '../../helpers/debounce';
import ChatWidget from '../chatWidget/chatwidget';
import { useLocation } from 'react-router-dom';

const FormExchange = () => {
    const [walletConnected, setWalletConnected] = useState(optionsPayment[0].value);
    const [activeButton, setActiveButton] = useState(false);
    const [isVisiblePopup, setIsVisiblePopup] = useState(false);

    const { language } = useContext(LanguageContext);
    const text = translations.buySect[language];

    const [balance, setBalance] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [balanceTextTranslate, setBalanceTextTranslate] = useState('');

    const [recipientAddress, setRecipientAddress] = useState('');
    const [contractAddressTranslate, setContractAddressTranslate] = useState('');
    const [referralState, setReferralState] = useState('');
    const [isReferralEditable, setIsReferralEditable] = useState(true);

    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState({ value: '', label: '', icon: null });
    const [total, setTotal] = useState('');
    const [currentRate, setCurrentRate] = useState(0);

    const location = useLocation();
    useEffect(() => {
        // Получить параметр refid из URL
        const queryParams = new URLSearchParams(location.search);
        const refid = queryParams.get('refid');

        if (refid) {
            Cookies.set('refid', refid, { expires: 1 });
            setReferralState(refid);
            setIsReferralEditable(false);
        } else {
            const savedRefid = Cookies.get('refid');
            if (savedRefid) {
                setReferralState(savedRefid);
                setIsReferralEditable(false);
            }
        }
    }, [location]);

    const decimalsMapping = {
        usdt: 2,
        usdc: 2,
        eth: 6,
        sol: 4,
        bnb: 4,
    };

    const getBinanceTicker = useCallback(async (symbol) => {
        const res = await GetBinanceTicker(symbol);
        if (res.status === 200 && res.data.lastPrice) {
            setCurrentRate(parseFloat((parseFloat(res.data.lastPrice) * usdtRate).toFixed(6)));
        }
    }, []);

    const fetchRateAndUpdateTotal = async (value, type) => {
        const decimals = decimalsMapping[currency.value] || 2;
        try {
            const response = await GetBinanceTicker(currency.value.toUpperCase());
            if (response.status === 200 && response.data.lastPrice) {
                const rate = parseFloat(response.data.lastPrice);
                setCurrentRate(rate);
                if (type === 'amount') {
                    if (value && parseFloat(value) > 0) {
                        setTotal(parseFloat((parseFloat(value) * (rate * usdtRate)).toFixed(decimals)));
                    }
                } else if (type === 'total') {
                    if (value && parseFloat(value) > 0) {
                        setAmount(parseFloat(((parseFloat(value) / (rate)) / usdtRate).toFixed(decimals)));
                    }
                }
            }
        } catch (error) {
            console.log('get current rate error');
        }
    };

    const debouncedFetchRate = useCallback(
        debounce(fetchRateAndUpdateTotal, 500),
        [currency.value, currentRate]
    );

    const inputHandler = (e) => {
        const { name, value } = e.target;
        const decimals = decimalsMapping[currency.value] || 2;
        if (name === 'amountToPay') {
            if (value) {
                const regex = new RegExp('^\\d*(\\.\\d{0,' + decimals + '})?$');
                let formattedValue = regex.test(value);
                if (formattedValue) {
                    setAmount(value);
                    if (currency.value !== 'usdt') {
                        debouncedFetchRate(value, 'amount');
                    } else {
                        setTotal(parseFloat((parseFloat(value) * parseFloat(usdtRate)).toFixed(6)));
                    }
                }
            } else {
                setAmount('');
                setTotal('');
            }
        } else if (name === 'menyGet') {
            const regex = new RegExp('^\\d*(\\.\\d{0,6})?$');
            if (value) {
                let formattedValue = regex.test(value);
                if (formattedValue) {
                    setTotal(value);
                    if (parseFloat(value) > 0) {
                        if (currency.value !== 'usdt') {
                            debouncedFetchRate(value, 'total');
                        } else {
                            setAmount(parseFloat((parseFloat(value) / parseFloat(usdtRate)).toFixed(decimals)));
                        }
                    }
                }
            } else {
                setTotal('');
                setAmount('');
            }
        }
    };

    const handleChangeSelect = async (e, name) => {
        if (name === 'payMethod') {
            setWalletConnected(e.value);
            if ((e.value === 'eth' && currentChain === 42161) || (e.value === 'bnb' && currentChain === 56)) {
                return;
            }
            if (isEthConnected) {
                if (e.value === 'bnb') {
                    await openEthWallet('bnb');
                } else if (e.value === 'eth') {
                    await openEthWallet('eth');
                }
            }
            updateBalanceAndAddress();
        }
    };

    const handleChangeCurrencySelect = (e) => {
        if (e.value === currency.value) return;
        setTotal('');
        setAmount('');
        setCurrency({
            value: e.value,
            label: e.label,
            icon: e.icon,
        });
    };

    const {
        open: openSolWallet,
        isConnected: isSolConnected,
        walletAddress: walletAddressSolana,
        balance: balanceSolana,
        sendTransaction,
        disconnect: solDisconnect,
    } = useSolanaConnect();

    const {
        walletInfo: ethWalletInfo,
        balance: ethBalance,
        selectedToken: ethSelectedToken,
        setSelectedToken: setEthSelectedToken,
        sendTokens: sendEthToken,
        disconnect: ethDisconnect,
        open: openEthWallet,
        isConnected: isEthConnected,
        currentChain,
    } = useEthConnect();
    //

    const handleConnection = async () => {
        if (walletConnected === 'eth' || walletConnected === 'bnb') {
            await openEthWallet(walletConnected);
        } else if (walletConnected === 'sol') {
            openSolWallet();
        }
    };

    const currentWalletHandler = () => {
        if (walletConnected === 'eth' || walletConnected === 'bnb') {
            return isEthConnected;
        } else if (walletConnected === 'sol') {
            return isSolConnected;
        }
    };

    const handlePurchase = async () => {
        if (walletConnected === 'eth' || walletConnected === 'bnb') {
            return sendEthToken(amount, referralState);
        } else if (walletConnected === 'sol') {
            return sendTransaction(amount);
        }
    };

    const handleDisconnect = async () => {
        if (walletConnected === 'eth' || walletConnected === 'bnb') {
            ethDisconnect();
        } else if (walletConnected === 'sol') {
            solDisconnect();
        }
    };

    const handlePopup = () => {
        setIsVisiblePopup((prev) => !prev);
    };

    useEffect(() => {
        if (walletConnected === 'eth') {
            if (currency.value === 'eth') {
                setEthSelectedToken('ETH');
            } else if (currency.value === 'usdt') {
                setEthSelectedToken('USDT');
            } else if (currency.value === 'usdc') {
                setEthSelectedToken('USDC');
            }
            setBalance(ethBalance);
            setWalletAddress(ethWalletInfo.address);
            setBalanceTextTranslate(text?.ethBalance);
            setContractAddressTranslate(text?.contractAdressEth);
        } else if (walletConnected === 'sol') {
            setBalance(balanceSolana);
            setWalletAddress(walletAddressSolana);
            setBalanceTextTranslate(text?.solBalance);
            setContractAddressTranslate(text?.contractAdressSol);
        } else if (walletConnected === 'bnb') {
            if (currency.value === 'bnb') {
                setEthSelectedToken('BNB');
            } else if (currency.value === 'usdt') {
                setEthSelectedToken('USDT');
            } else if (currency.value === 'usdc') {
                setEthSelectedToken('USDC');
            }
            setBalance(ethBalance);
            setWalletAddress(ethWalletInfo.address);
            setBalanceTextTranslate(text?.ethBalance);
            setContractAddressTranslate(text?.contractAdressEth);
        }
    }, [walletConnected, isEthConnected, isSolConnected, ethBalance, balanceSolana, currency, ethWalletInfo]);

    useEffect(() => {
        if (walletConnected === 'eth' || walletConnected === 'bnb') {
            setBalanceTextTranslate(text?.ethBalance);
            setContractAddressTranslate(text?.contractAdressEth);
        } else if (walletConnected === 'sol') {
            setBalanceTextTranslate(text?.solBalance);
            setContractAddressTranslate(text?.contractAdressSol);
        }
    }, [language]);

    useEffect(() => {
        if (currencyOptions.length > 0) {
            setCurrency(currencyOptions[0]);
        }
    }, [currencyOptions, walletConnected]);

    useEffect(() => {
        setAmount('');
        setTotal('');
        if (walletConnected === 'sol') {
            setCurrencyOptions(optionsCurrencySolana);
            if (currencyOptions.length > 0) {
                setCurrency(currencyOptions[0]);
            }
        } else if (walletConnected === 'eth') {
            setCurrencyOptions(optionsCurrencyEth);
            if (currencyOptions.length > 0) {
                setCurrency(currencyOptions[0]);
            }
        } else if (walletConnected === 'bnb') {
            setCurrencyOptions(optionsCurrencyBnb);
            if (currencyOptions.length > 0) {
                setCurrency(currencyOptions[0]);
            }
        }
    }, [walletConnected, currencyOptions]);

    useEffect(() => {
        if (currency.value && currency.value !== 'usdt') {
            getBinanceTicker(walletConnected.toUpperCase(), currency.value);
        } else if (currency.value && currency.value === 'usdt' || currency.value === 'usdc') {
            setCurrentRate(usdtRate);
        }
    }, [currency]);

    const updateBalanceAndAddress = (chainId) => {
        if (chainId === 56) {
            setBalance(ethBalance);
            setWalletAddress(ethWalletInfo.address);
            setBalanceTextTranslate(text?.ethBalance);
        } else if (chainId === 42161) {
            setBalance(ethBalance);
            setWalletAddress(ethWalletInfo.address);
            setBalanceTextTranslate(text?.ethBalance);
        } else if (walletConnected === 'sol') {
            setBalance(balanceSolana);
            setWalletAddress(walletAddressSolana);
            setBalanceTextTranslate(text?.solBalance);
        }
    };

    useEffect(() => {
        updateBalanceAndAddress(currentChain);
    }, [currentChain, walletConnected, ethBalance, ethWalletInfo, balanceSolana, walletAddressSolana]);

    useEffect(() => {
        if (isVisiblePopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isVisiblePopup]);

    const isBuyDisabled = () => {
        if (!amount || !total) {
            return true;
        } else if (currency.value === 'usdt' && parseFloat(amount) < 5) {
            return true;
        } else if (currency.value !== 'usdt' && currentRate && (parseFloat(amount) * currentRate) < 5) {
            return true;
        } else return false;
    };

    useEffect(() => {
        setActiveButton(currentWalletHandler());
    }, [walletConnected, isEthConnected, isSolConnected]);

    useEffect(() => {
        if (walletConnected === 'eth' || walletConnected === 'bnb') {
            setRecipientAddress(process.env.REACT_APP_ETH_WALLET_ADDRESS);
        } else if (walletConnected === 'sol') {
            setRecipientAddress(process.env.REACT_APP_SOL_WALLET_ADDRESS);
        }
    }, [walletConnected]);

    useEffect(() => {
        if (currentChain === 56) {
            setWalletConnected('bnb');
        } else if (currentChain === 42161) {
            setWalletConnected('eth');
        }
    }, [currentChain]);

    const customOption = (option) => (
        <div className="option-with-ico">
            {option.icon}
            {option.label}
        </div>
    );

    return (
        <>
            {((isEthConnected && (walletConnected === 'eth' || walletConnected === 'bnb'))) && (
                <div className="wallet-info">
                    <div>
                        <p>{balanceTextTranslate}: <b>{balance}</b></p>
                        <p>{text?.connectedAddress || ''}: {walletAddress && walletAddress?.slice(0, 6)}...{walletAddress && walletAddress?.slice(-4)}</p>
                    </div>
                    <div className="buttons-box">
                        <Tooltip tooltipText={text?.tooltipDisconnect || ''}>
                            <Btn type="button" className="white" onClick={handleDisconnect}>
                                <img src="/img/disconnect-ico.svg" alt="disconnect" />
                            </Btn>
                        </Tooltip>
                    </div>
                </div>
            )}
            <div className="form-box g-box">
                <div className="fields-list g-box">
                    <div className="form-group">
                        <label>{text?.selectCurrency || ''}</label>
                        <Select
                            name="currency"
                            id="currency"
                            options={currencyOptions}
                            value={currency}
                            getOptionLabel={customOption}
                            isSearchable={false}
                            placeholder={false}
                            closeMenuOnScrolls
                            onChange={e => handleChangeCurrencySelect(e)}
                        />
                    </div>
                    <div className="form-group">
                        <label>{text?.selectPayMethod || ''}</label>
                        <Select
                            name="payMethod"
                            id="payMethod"
                            options={optionsPayment}
                            defaultValue={optionsPayment[0]}
                            getOptionLabel={customOption}
                            isSearchable={false}
                            placeholder={false}
                            closeMenuOnScrolls
                            value={optionsPayment.find(option => option.value === walletConnected)}
                            onChange={(e) => handleChangeSelect(e, 'payMethod')}
                        />
                    </div>
                    <div className="form-group">
                        <label>{currencyOptions.find(option => option.value === currency.value)?.label} {text?.youBuy || ''} min 5 USD</label>
                        <Input
                            type="number"
                            id="amountToPay"
                            name="amountToPay"
                            icon={currencyOptions.find(option => option.value === currency.value)?.icon.props.src}
                            value={amount}
                            onChange={e => inputHandler(e)}
                            placeholder=""
                            min="0"
                        />
                    </div>
                    <div className="form-group">
                        <label>
                            {text?.menyGet || ''}
                            <Tooltip
                                tooltipText={text?.tooltipTGE || 'The current token price is $0.00016. When purchasing, you\'ll also pay a network fee.'}
                            >
                                <span className="i-btn"></span>
                            </Tooltip>
                        </label>
                        <Input
                            type="number"
                            id="menyGet"
                            name="menyGet"
                            icon="/img/input-icons/meny-icon.svg"
                            value={total}
                            onChange={e => inputHandler(e)}
                            placeholder=""
                            min="0"
                        />
                    </div>
                    <div className="form-group refferal">
                        <label>{text?.referral || ''}</label>
                        <Input
                            type="text"
                            id="referralCode"
                            name="referralCode"
                            placeholder="Add"
                            value={referralState}
                            readOnly={!isReferralEditable}
                            onChange={(e) => setReferralState(e.target.value)}
                        />
                    </div>
                </div>
                <div>
                    {activeButton ?
                        <Btn type="button" className="big" onClick={handlePopup} disabled={isBuyDisabled()}>{text?.textButtonBuy || ''}</Btn>
                        :
                        <Btn type="button" className="big" onClick={handleConnection}>{text?.textButtonConnect || ''}</Btn>
                    }
                </div>
                <div>
                    <ChatWidget />
                </div>
            </div>

            {isVisiblePopup && (
                <Popup
                    onClickBt={handlePurchase}
                    handlePopup={handlePopup}
                />
            )}
        </>
    );
};

export default FormExchange;
